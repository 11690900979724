@tailwind base;
@tailwind components;
@tailwind utilities;
*{
    font-family: Inter, sans-serif;
}

.stroke {
    stroke-linecap: round;
}

    .dark .path-track {
        stroke-linecap: round;
        stroke-dasharray: 175;
        stroke-width: 1px;
        stroke-dashoffset: 130;
        stroke: #60a5fa;
        fill: none;
        animation: dash2 5s ease-in-out infinite;
    }
    .dark .path {
        stroke-dasharray: 175;
        stroke-width: 3px;
        stroke-dashoffset: 0;
        stroke: #60a5fa;
        fill: none;
        opacity: 0.5;
        animation: dash 3s ease-in-out infinite;
    }

    .dark ::-webkit-scrollbar {
        width: 10px;
    }

    .dark ::-webkit-scrollbar-track {
        background-color: #0f172a;
    }

    .dark ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #60a5fa95;
    }


.path-track {
        stroke-linecap: round;
        stroke-dasharray: 175;
        stroke-width: 1px;
        stroke-dashoffset: 130;
        stroke: #2563eb;
        fill: none;
        animation: dash2 5s ease-in-out infinite;
    }

.path {
    stroke-dasharray: 175;
    stroke-width: 3px;
    stroke-dashoffset: 0;
    stroke: #2563eb;
    fill: none;
    opacity: 0.5;
    animation: dash 3s ease-in-out infinite;
}

@keyframes dash2 {
    from {
        stroke-dashoffset: 130;
    }
    to {
        stroke-dashoffset: 480;
    }

}

@keyframes dash {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 350;
    }
}

@layer base {
    @font-face {
        font-family: 'Avant Garde';
        font-weight: 500;
        src: url('../public/fonts/AvantGardeITCbyBT-Demi.otf') format('opentype');
    }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #f1f5f9;

}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
  background-color: #2563eb95;
}

::-webkit-search-cancel-button {
    -webkit-appearance: none;
    padding: 2px 10px;
}

.slider {
    --color: red;
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 20px; /* Specified height */
    border-radius: 100px;
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance:none;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    @apply bg-variant-10;
    border: 4px solid var(--color);
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background-color: var(--color);
    overflow: visible;
    cursor: pointer;
}

table {
  border-collapse: collapse;
}

thead {
    position: sticky;
    top: 0;
}